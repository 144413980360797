/*MAIN CONTENT*/


*{
    margin: 0;
    padding: 0;
}

p{
    margin: 1px 0;
}

.all-button{
    border-radius: 5px;
}

/*ANTD TABLE ROW*/
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: #fff9f0;
    cursor: pointer;
}


/*General*/
.containers{
    min-height: 80vh;
    background: white;
    padding: 20px;
    border-radius: 5px;
}

.basic-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.main-content{
    margin: 25px;
}

.center{
    border-radius: 5px;
    background: white;
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    background: white;
    padding: 40px;
    border-radius: 5px;
    margin-bottom: 20px;
}


.header{
    font-size: 25px;
    font-weight: bold;
    color: black;
}

.past-trip-top{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center
}


.filterContainer{
    display: flex;
    width: 100%
}

.chart-filter-container{
    display: flex;
    margin-bottom: 30px;
    flex:1;
    justify-content: flex-end;
}

.chart-range-picker{
    border-radius: 8px;
}


.past-trip-input{
    width: 20%;
    margin-right: 20px;
    border-radius: 8px;
}
.past-trip-select{
    width: 100%;
}

.selectContainer{
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    width: 20%;
    margin-right: 20px;

}

.range-picker{
    border-radius: 8px;
    width: 20%;
    margin-right: 5%;
}

.table{
    /*margin: auto;*/
    background: white;
    margin-bottom: 30px;
}

/*Payout Screen*/
.payment-history-table{
    margin-top: 10px;
}


.payment-history-table .price-range{
    border-radius: 5px;
    width: 20%;
}

.payment-container{
    display: flex;
    justify-content: space-between
}

.payment-his{
    min-height: 80vh;
}


/*Driver List Screen*/

.driver-search-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.pending-driver{
    margin-top: 30px;
}

.driver-list-name{
    color: #E27626
}

.driver-list-name:hover{
    text-decoration: underline;
}

.add-driver-container{
    display: flex;
    align-items: center;
}

.add-driver-container p{
    color: #E27626;
    margin-right: 10px;
    cursor: pointer;
}

/*Sign up driver for company*/
.signup-driver-inner{
    width: 60%;
    margin: 0 auto;
}

.signup-driver-inner p{
    text-align: center;
}

.register-driver-modal{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-driver-modal p{
    font-size: 18px;
}



/*Modal form*/

.add-info-modal{
    border-radius: 50px;
}

.modal-form{
    padding: 0 10px;
}

.modal-label{
    font-weight: bold;
}

.modal-input{
    border-radius: 5px;
    height: 50px;
}

.modal-button{
    border-radius: 8px;
}


/*Payout Screen*/


.payout-header-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payout-header-container h2{
    font-size: 20px;
    font-weight: bold;
}


/*Profile Screen*/

.profile-container{
    background: white;
   padding: 30px;
}

.image-upload{
    margin: 20px auto;
    width: 100px
}

.profile-form-container{
    width: 50%;
    margin: auto;
}

.profile-form-container .label{
    font-weight: bolder;
}

.profile-form-container .input{
    borderRadius: 8px
}


/*Add Vehicles*/

.add-vehicle{
    background: white;
    min-height: 80vh;
    padding: 30px;
}

.add-vehicle .steps{
    width: 80%;
    margin: 50px auto
}


/*Upload Licence Screen*/

.upload-licence{
    width: 40%;
    margin: auto
}

.upload-licence h2{
    font-weight: bolder;
    margin-bottom: 40px;
}

.upload-licence .buttons{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.upload-licence Button{
    width: 48%;
    border-radius: 8px;
}


/* Vehicle Form*/
.vehicle-form{
    width: 50%;
    margin: auto;
}

.vehicle-form h2{
    font-weight: bolder;
}

.vehicle-form .buttons{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.bi-container{
    width: 30%;
    margin: 20px auto 0;
}


.vehicle-form .b2{
    width: 48%;
    border-radius: 8px;
}

.vehicle-form .radio{
    width: 100%;
}


/* Add Bank Account*/
.add-bank-account{
    width: 40%;
    margin: auto;
}

.add-bank-account .buttons{
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
}

.add-bank-account Button{
    width: 45%;
    margin: 10px;
    border-radius: 8px;
}

.add-account-select{
    border-radius: 5px;
}

.selectContainer2{
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    width: 100%;
    margin-right: 20px;

}

.checkbox-container{
    display: flex;
}
.checkbox-container p{
    font-size: 13px;
    margin-left: 10px;
}


/*Change Password*/

.change_password{
    padding: 40px 0 0 40px;
    background: white
}

.change_password.label{
    font-weight: 500;
}
.change_password h2{
    font-weight: bold;
    margin-bottom: 20px;
}

.change_password-form{
    width: 50%;
}

.change_password-form .button{
    width: 100%;
    border-radius: 5px;
}

/* Setting Sidebar*/
.settings-sidebar{
    height: 80vh;
}

.settings-link{
    font-size: 12px;
}



/*Notification Screen*/

.notification{
    padding: 40px;
    background: white;
}

.notify{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid lightgrey;
}

.notify .inner{
    display: flex;
    align-items: center;
}

.notify .icon{
    font-size: 35px;
    margin-right: 10px;
    color: royalblue;
}

.notify h2{
    color: #072F5F;
    position: relative;
    top: 10px
}

.notify p{
    font-size: 15px;
    color: dimgrey;
}

.not-header{
    font-weight: bolder;
    font-size: 30px;
    margin-bottom: 40px;
    color: #072F5F;
}



/*Billing Screen*/
.billing{
    background: white;
    padding: 40px;
}

.billing h2{
    color: black;
    font-size: 25px;
    font-weight: bolder;
    margin: 0;
    padding:0
}

.billing-button-cont{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end
}

.billing-button{
    border-radius: 5px;
    cursor: pointer;
}

.billing-card {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #E4E7EC;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.billing-center{
    background: white;
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}




/*Calendar Styles*/

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events-text{
    font-size: 9px;
}

.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notes-month {
    font-size: 28px;
    text-align: center;
}
.notes-month section {
    font-size: 28px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    margin-left: 8px;
    display: none;
}

.ant-picker-calendar-header {
    padding-right: 20px;
}

.calendar-item{
    background-color: #fdfcfc;
    padding: 18px 10px 10px 10px;
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
    border-color: #6d9fff;
    -webkit-box-shadow: -1px 13px 16px -5px #595959;
    box-shadow: 0px 1px 10px -5px #595959;
    margin-bottom: 10px;
    cursor: pointer;
    color: black;
}

.calendar-item:hover{
    background: #F5FCFF;
    color: #072F5F;
}


.calendar-modal-item{
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 10px;
    transition: all 0.5s ease ;
}

.calendar-modal-item:hover{
    background:  rgb(235, 238, 238)
}
/**/



/* Total Revenue*/
.total-revenue{
    background: white;
    padding: 30px;
    border-radius: 8px;
    min-height: 450px;
}

.revenue-margin{
    margin-top: 20px;
}

.total-revenue .header{
    font-weight: 600;
}

.total-revenue .date{
    color: dimgrey;
}

.total-revenue-content{
    margin: 80px 30px 0 0;
}

.total-revenue-content .header{
    font-weight: bolder;
    font-size: 20px;
}
.total-revenue-content .growth{
    color: #73b504;
}

.total-revenue-content .loss{
    color: #b5040a;
}
.total-revenue-content .p2{
    color: dimgray;
}

.report-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
.border-radius{
    border-radius: 8px;
}

.report-container .s1{
    height: 40px;
    margin-right: 10px;
    border: 1px solid lightgrey;
}


/* Dashboard Screen*/

.dashboard-container1{
    display: flex;
    justify-content: space-between;
}

.dashboard-container1 .div1{
    width: 75%;
    margin-right: 0.8%;
}

.dashboard-container1 .div2{
    width: 27%;
}

.dashboard-payout {
    background: white;
    border-radius: 5px;
    padding: 15px;
    min-height: 450px
}

.dashboard-payout p{
    padding-top: 10px
}
.dashboard-payout .d1{
    text-align: center
}

.dashboard-payout a{
    font-size: 15px
}


.dashboard-container2{
    display: flex;
    margin-top: 10px;
    justify-content: center;
    /*min-height: 1000px;*/
}

.dashboard-container2 .div1{
    padding: 10px;
    background: white;
    border-radius: 5px;
    width:32.7%;
}

.dashboard-map{
    height: 80vh;
    width: 100%;
    border: 20px solid white;
    margin-top: 20px
}

.mid-margin{
    margin: 0 1%;
}

.dashboard-card-header{
    text-align: center
}

.dashboard-card-header h2{
    font-weight: bolder;
}

.dashboard-card-header p{
    font-weight: bold;
}



/* Upcoming Trip Item*/
.trip-item-container{
    border-radius: 5px;
    background: white;
    margin-top: 20px;
}

.trip-item, .second-trip-item{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
}

.second-trip-item .div-item{
    width: 25%;
}


.second-trip-item .second-item{
    width: 40%;
}

.second-trip-item h1{
    font-weight: bolder;
    font-size: 20px;
}

.second-trip-item .sub-div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.second-trip-item .tdo{
    margin-top: 50px;
}

.trip-item .div1{
    width: 40%;
    margin-top: 10px;
}

.trip-item .div2,.trip-item .div3{
    width: 25%;
    margin: 0 20px
}



.trip-item .h1{
    font-weight: bold;
}

.trip-item .address{
    position: relative;
    top: 5px
}

.trip-item p{
    color: dimgray;
}

.trip-item .right{
    font-size: 15px;
    color: black;
}

.trip-item-map{
    height: 30vh;
    width: 100%;
}

.upcoming-item-map{
    height: 40vh;
    width: 100%;
    border: 20px solid white;
    margin-top: 20px
}

/*Available Drivers*/
.available-drivers{
}

/*Driver Item*/

.driver-item{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(216, 216, 216);
    background: white;
}

.driver-item .avatar-name{
    width: 30%;
    display: flex;
}

.driver-item-avatar{
    margin-right: 10px;
}

.driver-item .link{
    font-weight: bolder;
    font-size: 13px;
}

.driver-item .content{
    width: 20%;
}

.driver-item .p1{
    font-weight: bolder;
    font-size: 18px;
}

.driver-item .p2{
    color: rgb(104, 103, 103);
}


/*Available trips*/

.available-trips{
}



/* Live Trip Screen*/
.live-trip{
    background: white;
    border: 40px solid white;
    border-bottom: 20px solid white;;
    border-radius: 5px;
}

.live-trip .back{
    font-size: 30px;
}

.live-trip-map{
    height: 50vh;
    width: 100%;
    /*border: 20px solid white;*/
    margin-top: 30px
}

.live-trip .div1{
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
}

.live-trip .status{
    width: 30%
}

.live-trip p{
    color: dimgray;
}

.live-trip .p1{
    position: relative;
    top: 20px;
}

.live-trip .p2, .live-trip .progress{
    position: relative;
    bottom: 14px;
}
.filterContainer
.trip-details-container{
    min-height: 80vh;
    width: 100%;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
    border: 1px solid #F5FCFF;
    padding: 20px;
}

.trip-details-container h3{
    margin-top: 10px;
    color: #072F5F;
    font-size: 20px;
    font-weight: bold;
}

.trip-content{
    display: flex;
}

.trip-content .key{
    font-weight: bold;
    font-size: 15px;
    width: 20%
}

.trip-details-container{
    background: white;
    border-radius: 5px;
    padding: 20px;
}

.trip-details-head{
    display: flex;
    align-items: center;
}
.trip-details-head .icon{
   font-weight: bold;
    margin-right: 20px;
}

.trip-details-container .inner{
    margin-bottom: 20px;
}


/*Edit Vehicle*/

.edit-vehicle{
    background: white;
    width: 100%;
    padding: 30px 0;
}

/*Driver details screen*/

.driver-details{
    background: white;
    min-height: 90vh;
    padding: 50px;
    border-radius: 5px;
}

.driver-remove{
    border: 1px red solid;
    color: red;
}

.driver-current-trip, .driver-past-trips{
    background: white;
    border-radius: 5px;
    margin-top: 25px;
    padding: 20px 50px;
}



@media only screen and (max-width: 700px){

    .trip-content{
        flex-direction: column;
    }

    .trip-content .key{
        width: 100%;
    }
}

@media only screen and (max-width: 1110px){

    .dashboard-container1{
        display: block;
    }

    .dashboard-container1 .div1{
        width: 100%;
        margin-right: 0
    }

    .dashboard-container1 .div2{
        width: 100%
    }

    .dashboard-payout{
        min-height: auto;
        margin-top: 20px;
    }
    .live-trip{
        border: 10px solid white;
        border-bottom: 5px solid white;
    }

    .live-trip .div1{
        display: block;
        padding: 10px 10px;
    }

    .live-trip .status{
        width: 100%
    }
}




@media only screen and (max-width: 800px){
    .header{
        font-size: 20px;
    }
    /*.payment-history-container{*/
    /*    background: white;*/
    /*}*/

    .main-content{
        margin: 10px;
    }

    .inner-container{
        padding: 30px 20px;
    }

    .past-trip-top{
        display: block;
    }

    .filterContainer{
        display: block;
    }

    .past-trip-input{
        width: 100%;
        margin-bottom: 10px;
    }
    .selectContainer{
        width: 100%;
        margin-bottom: 10px;
    }
    .range-picker{
        width: 100%;
        margin-bottom: 10px ;
    }

    /*Payment History*/
    .payment-container{
        display: block;
    }

    .payment-container .clear-filter-btn{
        margin-top: 10px;
    }

    /*.price-range{*/
    /*    width: 90%;*/
    /*}*/

    .price-arrow{
        width: 10%;
    }

    /*Driver List Screen*/

    .driver-search-container{
        display: block;
    }

    .add-driver{
        width: 100%;
    }

    .add-driver-container{
        display: block;
    }

    .add-driver-container p{
        text-align: center;
        margin: 10px 0;
    }

/*    Payout Screen*/

    .payout-header-container{
        display: block;
    }

    .payout-header-container h2{
        font-size: 15px;
    }

/*    Profile Screens*/
    .profile-form-container{
        width: 95%;
    }

    .profile-form-container .label{
        font-weight: normal;
    }

    /*Upload Licence Screen*/

    .upload-licence{
        width: 80%;
    }


/*    Vehicle Form*/
    .vehicle-form{
        width: 90%;
    }

/*    Add Bank account*/
    .add-bank-account{
        width: 90%;
    }

/*    Change Password*/
    .change_password h2{
        margin-left: 10px;
        font-size: 18px;
    }

    .change_password {
        padding-left: 0;
        /*margin: 0;*/
        /*height: auto;*/
    }
    .change_password-form{
        width: 90%;
        margin: auto;
    }

    .settings-sidebar{
        height: 100%;
        margin-bottom: 0px;
    }



/*    Notification Screen*/
    .notification{
        padding: 10px;
    }

    .not-header{
        margin-bottom: 10px;
        font-size: 20px;
    }

    .notify h2{
        font-size: 10px;
        top: 0
    }

    .notify p{
        display: none;
    }
    .notify .icon{
        font-size: 20px;
    }

    /*Billing Screen*/
    .billing{
        padding: 10px;
    }

    .billing-button-cont{
        justify-content: center
    }


    /* Total Revenue*/
    .total-revenue{
        padding: 10px;
    }

    .total-revenue .c1{
        margin-bottom: 20px;
    }

    .total-revenue-content{
        margin: 50px 0 0 0;
    }

    /*.report-container{*/
    /*    display: block;*/
    /*}*/

    .report-container .s1{
        margin-bottom: 10px;
        margin-right: 10px;
        /*width: 100%;*/
    }

    .report-container Button{
        width: 100%;
        margin-bottom: 20px;
    }


    .dashboard-container2{
        display: block;
    }

    .dashboard-container2 .div1{
        width: 100%;
        margin: 10px auto 0;
    }

    .mid-margin{
        margin: auto;
    }


    /* Upcoming Trip Item*/
    .trip-item, .second-trip-item{
        display: block;
    }

    .second-trip-item .sub-div{
        margin: 20px 0;
    }
    .second-trip-item .tdo{
        margin-top: 10px;
    }

    .second-trip-item .div-item{
        width: 100%;
    }

    .second-trip-item .second-item{
        width: 100%;
    }

    .trip-item .div1, .trip-item .div2,.trip-item .div3{
        width: 100%;
    }

    .trip-item .div2,.trip-item .div3{
        margin: 0
    }


    .trip-item .right{
        font-size: 25px;
    }

    .trip-item .right-container{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    /*Driver Item*/

    .driver-item{
        display: block;
    }

    .driver-item .p1{
        font-size: 13px;
    }
    .driver-item .p2{
        font-size: 10px;
    }

    .driver-item .avatar-name{
        width: 100%;
    }

    .driver-item .content{
        margin-top: 10px;
        width: 100%;
    }

    /* Live Trip Screen*/
    .live-trip{
        border: 10px solid white;
        border-bottom: 5px solid white;
    }

    .live-trip .div1{
        display: block;
        padding: 10px 10px;
    }

    .live-trip .status{
        width: 100%
    }
}


@media only screen and (max-width: 400px){

    .main-content{
        margin: 10px;
    }

    .table-container{
        background: white;
        width: 100%;
    }

    /* Total Revenue*/

    .report-container{
        display: block;
        margin: auto;
    }

    .report-container .s1{
        margin-bottom: 10px;
        margin-right: 0;
        /*width: 100%;*/
    }


}

@media only screen and (max-width: 500px){

    /*Sign up driver for company*/
    .signup-driver-inner{
        width: 80%;
    }

}

